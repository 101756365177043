div .container {
    padding: 20px 15px 0 30px;
    max-width: 500pt;
}

p {
    font-size: 12pt;
}

.m0 {
    margin: 0;
}

.mb0 {
    margin-bottom: 0;
}

h3 {
    font-size: 12pt;
}

.h3__date {
    text-decoration: none;
    font-weight: normal;
    font-style: italic;
    font-size: 10pt;
}