.Chat {
  padding: 0;
  margin: 0;
  font-size: small;
}

.Chat section {
  max-width: 400px;
  margin: 10px;
  position: fixed;
  bottom: 0;
}

.Chat .message {
  display: inline;
}

.Chat .message h6 {
  margin: 0;
}

.Chat .message p {
  margin: 0;
  padding-bottom: 15px;
  font-size: small;
}

.Chat .sent h6, .sent p, .Chat .sent small {
  padding-left: 250px;
}

.Chat form input[type="text"] {
  width: 90%;
}

.Chat .login {
  width: 400px;
  height: 50px;
}